<template>
  <div
    :style="{ background: loginScreenStyle.background }"
    class="session-wrapper login-screen">
    <v-container
      grid-list-xl
      class="column-layout justify-center full-height">
      <div class="text-xs-center">
        <div class="session-table-cell row-layout-center">
          <div class="session-content login-content">
            <div class="login-logo">
              <img
                :src="require('../../assets/images/logo.svg')"
                alt="Drive Me">
            </div>
            <div
              v-if="this.$route.query.token == null"
              class="white-14 mb-2 pb-2">
              <div class="mb-4">
                No token
              </div>

              <v-btn
                color="pink"
                large
                dark
                block
                to="/session/login">Terug naar login</v-btn>
            </div>
            <div v-else>
              <h2 class="mb-3 white-18">Nieuw wachtwoord instellen</h2>
              <p class="fs-14 white-12">Vul een nieuw wachtwoord in</p>
              <v-form
                ref="resetForm"
                v-model="valid"
                class="mb-4 mt-4">
                <v-text-field
                  v-model="newPassword"
                  :rules="passwordRules"
                  type="password"
                  dark
                  label="Nieuw wachtwoord"
                  required
                />
                <v-text-field
                  v-model="newPasswordRepeat"
                  :rules="passwordRules"
                  type="password"
                  dark
                  label="Herhaal wachtwoord"
                  required
                />
                <v-btn
                  id="reset-password-submit"
                  :disabled="!valid"
                  class="mt-2"
                  large
                  dark
                  color="pink"
                  block
                  @click="submit"><span>Wachtwoord opslaan & Inloggen</span></v-btn>
              </v-form>
            </div>
          </div>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import Request from '../../helpers/request'
// import { storeToken } from '../../helpers/tokenHelper'

export default {
  mixins: [Request],
  data () {
    return {
      valid: true,
      newPassword: '',
      newPasswordRepeat: '',
      passwordRules: [v => !!v || 'Is verplicht']
    }
  },
  computed: {
    loginScreenStyle () {
      return {
        background: `linear-gradient(to bottom, ${this.$store.getters.colorGuide.login.gradient.from} 40vh, ${this.$store.getters.colorGuide.login.gradient.to} 100%), url(${this.$store.getters.colorGuide.login.image}) ${this.$vuetify.breakpoint.xsOnly ? this.$store.getters.colorGuide.login.imageParams : this.$store.getters.colorGuide.login.imageParamsDesktop}`
      }
    }
  },
  methods: {
    submit () {
      if (this.$refs.resetForm.validate()) {
        let body = {
          data: {
            token: this.$route.query.token,
            password: this.newPassword,
            passwordRepeat: this.newPasswordRepeat
          }
        }

        this.requestNoAuth('POST', '/recover', body, ({ data }) => {
          this.$notify({
            group: 'topRight',
            type: 'success',
            text: 'Wachtwoord ingesteld'
          })

          // storeToken(data)

          this.constructRequests()

          this.request('GET', '/me', {}, ({ data }) => {
            this.$store.dispatch('loginUser', { data })
            this.$store.dispatch('setUser', data)

            window.location = '/'
          })
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.login-content {
      width: 90%;
      max-width: 350px;
}
.login-logo {
    margin-bottom: 10%;
    margin-top: 40px;
}
</style>
